import React from "react";
import clsx from "clsx";

export const Skeleton = ({
	className,
	...props
}: React.ComponentProps<"div">) => (
	<div
		className={clsx("flex animate-pulse bg-neutral-200", className)}
		{...props}
	/>
);

const SkeletonTextHeading = ({
	className,
	...props
}: React.ComponentProps<"div">) => (
	<Skeleton className={clsx("h-[20px] rounded-md", className)} {...props} />
);

const SkeletonTextSubHeading = ({
	className,
	...props
}: React.ComponentProps<"div">) => (
	<Skeleton className={clsx("h-[15px] rounded-md", className)} {...props} />
);

const SkeletonTextBody = ({
	rows = 1,
	className,
	...props
}: React.ComponentProps<"div"> & { rows?: number }) => (
	<div className={className}>
		{Array.from({ length: rows }, (_, index) => (
			<Skeleton
				className={clsx(
					rows > 1 && "last:w-3/5",
					"my-[6px] h-[10px] rounded-md lg:my-[7px] lg:h-[12px]",
				)}
				key={index}
				{...props}
			/>
		))}
	</div>
);

const SkeletonCircle = (props: React.ComponentProps<"div">) => (
	<div {...props}>
		<Skeleton className="aspect-w-1 aspect-h-1 w-full rounded-full" />
	</div>
);

const SkeletonButton = (props: React.ComponentProps<"div">) => (
	<div {...props}>
		<Skeleton className="h-9 rounded-full" />
	</div>
);

Skeleton.Circle = SkeletonCircle;
Skeleton.TextHeading = SkeletonTextHeading;
Skeleton.TextSubHeading = SkeletonTextSubHeading;
Skeleton.TextBody = SkeletonTextBody;
Skeleton.Button = SkeletonButton;
