import React from "react";
import * as PropTypes from "prop-types";
import clsx from "clsx";

import { useCaptureGraphQLError } from "../../api";

import useEffectOnce from "../utilities/useEffectOnce";

export type SomethingWentWrongTrigger = Error | { message: string };

export const useCaptureSomethingWentWrong = ({
	trigger,
}: {
	trigger?: SomethingWentWrongTrigger;
}) => {
	const captureWhatWentWrong = useCaptureGraphQLError();
	useEffectOnce(() => {
		if (trigger) {
			captureWhatWentWrong(trigger);
		}
	});
};

const SomethingWentWrongMessage = ({
	isSmall = false,
}: {
	isSmall?: boolean;
}) => (
	<div className={clsx("grid", isSmall ? "gap-4" : "gap-6 md:gap-9")}>
		<h1
			className={clsx(
				"text-center",
				isSmall ? "text-heading-xs" : "text-heading-sm",
			)}
		>
			Something went wrong.
		</h1>
		<p
			className={clsx(
				"text-color-body text-center",
				isSmall ? "text-body-lg" : "text-body-xl",
			)}
		>
			Refresh the page or try again later.
		</p>
	</div>
);

SomethingWentWrongMessage.defaultProps = {
	isSmall: false,
};

SomethingWentWrongMessage.propTypes = {
	isSmall: PropTypes.bool,
};

export type SomethingWentWrongProps = React.ComponentProps<"div"> & {
	isSmall?: boolean;
	trigger?: SomethingWentWrongTrigger;
};

const SomethingWentWrong = ({
	trigger = new Error(
		"Something went wrong > An error occurred, but no explicit trigger was specified",
	),
	isSmall = false,
	...rest
}: SomethingWentWrongProps) => {
	useCaptureSomethingWentWrong({ trigger });

	return (
		<div className="max-w-container-lg mx-auto w-full" {...rest}>
			<div className="grid grid-cols-12">
				<div className="col-span-12 md:col-span-8 md:col-start-3">
					<SomethingWentWrongMessage isSmall={isSmall} />
				</div>
			</div>
		</div>
	);
};

SomethingWentWrong.Message = SomethingWentWrongMessage;

export default SomethingWentWrong;
