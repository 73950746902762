import React from "react";
import { Tag, TagProps, TagSize, TagVariant } from "@jasperlabs/jux-next";

export const AccreditedInvestorTag = (props: TagProps) => (
	<Tag
		{...props}
		variant={TagVariant.MutedYellow}
		size={TagSize.Small}
		aria-label="Explanation of wholesale"
	>
		Wholesale
	</Tag>
);

export const MultiAssetFundTag = (props: TagProps) => (
	<Tag
		{...props}
		variant={TagVariant.MutedBlue}
		size={TagSize.Small}
		aria-label="Explanation of multi-asset fund"
	>
		Multi-Asset
	</Tag>
);

export const SingleAssetFundTag = (props: TagProps) => (
	<Tag
		{...props}
		variant={TagVariant.MutedBlue}
		size={TagSize.Small}
		aria-label="Explanation of Single-Asset Fund"
	>
		Single-Asset
	</Tag>
);

export const ExitedFundTag = (props: TagProps) => (
	<Tag
		{...props}
		variant={TagVariant.MutedNeutral}
		size={TagSize.Small}
		aria-label="Explanation of Exited Fund"
	>
		Exited Fund
	</Tag>
);

export const FullySubscribedOfferTag = (props: TagProps) => (
	<Tag
		{...props}
		variant={TagVariant.MutedNeutral}
		size={TagSize.Small}
		aria-label="Explanation of Fully Subscribed Offer"
	>
		Fully Subscribed
	</Tag>
);

export const OpenOfferTag = (props: TagProps) => (
	<Tag
		{...props}
		variant={TagVariant.MutedSuccess}
		size={TagSize.Small}
		aria-label="Explanation of Open Offer"
	>
		Open
	</Tag>
);
