/* eslint-disable */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import { OfferTagsFragmentDoc } from '../../../components/organisms/opportunities/OfferTags/OfferTags.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OffersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type OffersQuery = { __typename: 'Query', offers: Array<{ __typename: 'Offer', id: string, slug: string, name: string, cardImageUrl?: string | null, status: Types.OfferStatus, hideOffer?: boolean | null, openDate?: any | null, subtitle?: string | null, isAccreditationRequired: boolean, fund: { __typename: 'Fund', id: string, blurb: string, isMarketplaceFund: boolean, assetStructure: Types.AssetStructure } }>, funds: Array<{ __typename: 'Fund', id: string, name: string, blurb: string, cardImageUrl: string, exitDetails?: { __typename: 'FundExitDetails' } | null }> };

export type CheckForOpenTradesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CheckForOpenTradesQuery = { __typename: 'Query', sellOrders: Array<{ __typename: 'SellOrder', id: string, status: Types.SellOrderStatus }> };


export const OffersDocument = gql`
    query Offers {
  offers {
    id
    slug
    name
    cardImageUrl
    status
    hideOffer
    openDate
    fund {
      id
      blurb
    }
    subtitle
    ...OfferTags
  }
  funds {
    id
    name
    blurb
    cardImageUrl
    exitDetails {
      __typename
    }
  }
}
    ${OfferTagsFragmentDoc}`;

/**
 * __useOffersQuery__
 *
 * To run a query within a React component, call `useOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOffersQuery({
 *   variables: {
 *   },
 * });
 */
export function useOffersQuery(baseOptions?: Apollo.QueryHookOptions<OffersQuery, OffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OffersQuery, OffersQueryVariables>(OffersDocument, options);
      }
export function useOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OffersQuery, OffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OffersQuery, OffersQueryVariables>(OffersDocument, options);
        }
export type OffersQueryHookResult = ReturnType<typeof useOffersQuery>;
export type OffersLazyQueryHookResult = ReturnType<typeof useOffersLazyQuery>;
export type OffersQueryResult = Apollo.QueryResult<OffersQuery, OffersQueryVariables>;
export const CheckForOpenTradesDocument = gql`
    query CheckForOpenTrades {
  sellOrders {
    id
    status
  }
}
    `;

/**
 * __useCheckForOpenTradesQuery__
 *
 * To run a query within a React component, call `useCheckForOpenTradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckForOpenTradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckForOpenTradesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckForOpenTradesQuery(baseOptions?: Apollo.QueryHookOptions<CheckForOpenTradesQuery, CheckForOpenTradesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckForOpenTradesQuery, CheckForOpenTradesQueryVariables>(CheckForOpenTradesDocument, options);
      }
export function useCheckForOpenTradesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckForOpenTradesQuery, CheckForOpenTradesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckForOpenTradesQuery, CheckForOpenTradesQueryVariables>(CheckForOpenTradesDocument, options);
        }
export type CheckForOpenTradesQueryHookResult = ReturnType<typeof useCheckForOpenTradesQuery>;
export type CheckForOpenTradesLazyQueryHookResult = ReturnType<typeof useCheckForOpenTradesLazyQuery>;
export type CheckForOpenTradesQueryResult = Apollo.QueryResult<CheckForOpenTradesQuery, CheckForOpenTradesQueryVariables>;