/* eslint-disable */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
export type OfferTagsFragment = { __typename: 'Offer', id: string, status: Types.OfferStatus, isAccreditationRequired: boolean, fund: { __typename: 'Fund', isMarketplaceFund: boolean, assetStructure: Types.AssetStructure } };

export const OfferTagsFragmentDoc = gql`
    fragment OfferTags on Offer {
  id
  status
  isAccreditationRequired
  fund {
    isMarketplaceFund
    assetStructure
  }
}
    `;