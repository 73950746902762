import React from "react";

export function SuppressEvent({
	doesPreventDefault = true,
	doesStopPropagation = true,
	...rest
}: React.ComponentProps<"div"> & {
	doesPreventDefault?: boolean;
	doesStopPropagation?: boolean;
}) {
	const suppress = (e: React.UIEvent) => {
		if (doesPreventDefault) {
			e.preventDefault();
		}
		if (doesStopPropagation) {
			e.stopPropagation();
		}
	};

	return (
		<div
			role="button"
			tabIndex={-1}
			onClick={suppress}
			onKeyPress={suppress}
			{...rest}
		/>
	);
}
