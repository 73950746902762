import React from "react";
import ReactMarkdown, { Options } from "react-markdown";
import { Link, Text } from "@jasperlabs/jux-next";
import clsx from "clsx";

type Props = { text: string; options?: Options; className?: string };

function RenderMarkdown({ text, options, className }: Props) {
	return (
		<ReactMarkdown
			className={clsx("space-y-5", className)}
			{...options}
			components={{
				p: ({ children }) => (
					<Text variant="body-xl" as="p">
						{children}
					</Text>
				),
				a: ({ children, href }) => (
					<Link href={href} target="_blank">
						{children}
					</Link>
				),
				ol: ({ children }) => (
					<ol className="list-decimal list-outside ml-5 space-y-2">
						{children}
					</ol>
				),
				ul: ({ children }) => (
					<ul className="list-disc list-outside ml-5 space-y-2">{children}</ul>
				),
				li: ({ children }) => (
					<Text variant="body-xl" as="li">
						{children}
					</Text>
				),
				h1: ({ children }) => (
					<Text variant="heading-default" as="h1">
						{children}
					</Text>
				),
				h2: ({ children }) => (
					<Text variant="heading-sm" as="h1">
						{children}
					</Text>
				),
				h3: ({ children }) => (
					<Text variant="heading-xs" as="h1">
						{children}
					</Text>
				),
				h4: ({ children }) => (
					<Text variant="subheading-default" as="h1">
						{children}
					</Text>
				),
				h5: ({ children }) => (
					<Text variant="subheading-sm" as="h1">
						{children}
					</Text>
				),
				h6: ({ children }) => (
					<Text variant="subheading-xs" as="h1">
						{children}
					</Text>
				),
				...options?.components,
			}}
		>
			{text}
		</ReactMarkdown>
	);
}

export default RenderMarkdown;
