import React from "react";

type Props = {
	condition: boolean;
	children: React.ReactNode;
	fallback?: React.ReactNode;
};

const RenderIfCondition = ({ condition, children, fallback }: Props) => {
	if (!condition) {
		return <>{fallback}</> ?? null;
	}
	return <>{children}</>;
};

export default RenderIfCondition;
