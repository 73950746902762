import React from "react";
import { gql } from "@apollo/client";

import {
	OpenOfferTag,
	MultiAssetFundTag,
	SingleAssetFundTag,
	AccreditedInvestorTag,
} from "./tags";
import { OfferTagsFragment } from "./OfferTags.generated";

export function OfferTags({ offer }: { offer: OfferTagsFragment }) {
	const { status, isAccreditationRequired, fund } = offer;

	const isOpen = status === "OPEN" || status === "OVERSUBSCRIBED";
	const isMultiAsset = fund.assetStructure === "MULTI_ASSET";
	const isSingleAsset = fund.assetStructure === "SINGLE_ASSET";

	return (
		<div className="flex flex-wrap gap-2">
			{isOpen && <OpenOfferTag />}
			{isMultiAsset && <MultiAssetFundTag />}
			{isSingleAsset && <SingleAssetFundTag />}
			{isAccreditationRequired && <AccreditedInvestorTag />}
		</div>
	);
}

OfferTags.fragment = gql`
	fragment OfferTags on Offer {
		id
		status
		isAccreditationRequired
		fund {
			isMarketplaceFund
			assetStructure
		}
	}
`;
