import React, { forwardRef } from "react";
import clsx from "clsx";

import type {
	PolymorphicComponentPropsWithRef,
	PolymorphicRef,
} from "../../types/polymorphic-component";
import { Tooltip, TooltipVariant } from "../Tooltip";
import {
	QuestionMarkReferenceSize,
	QuestionMarkReferenceVariant,
} from "../Tooltip/QuestionMarkReference";

/**
 * Specifies a tag's color scheme
 */
export enum TagVariant {
	Primary = "primary",
	Secondary = "secondary",
	Success = "success",
	Neutral = "neutral",
	Warning = "warning",
	Danger = "danger",
	Blue = "blue",
	Violet = "violet",
	Yellow = "yellow",
	MutedPrimary = "muted-primary",
	MutedSecondary = "muted-secondary",
	MutedSuccess = "muted-success",
	MutedWarning = "muted-warning",
	MutedDanger = "muted-danger",
	MutedNeutral = "muted-neutral",
	MutedBlue = "muted-blue",
	MutedViolet = "muted-violet",
	MutedYellow = "muted-yellow",
}

export enum TagSize {
	Small = "small",
	Default = "default",
}

export type TagProps<C extends React.ElementType = "span"> =
	PolymorphicComponentPropsWithRef<
		C,
		{
			/**
			 * The variant/color scheme of the tag.
			 * @default TagVariant.Primary
			 */
			variant?: TagVariant;

			/**
			 * The size of the tag.
			 * @default TagSize.Default
			 */
			size?: TagSize;

			/**
			 * Icon to display on left side of tag.
			 */
			iconLeft?: React.ReactNode;

			/**
			 * Icon to display on right side of tag.
			 */
			iconRight?: React.ReactNode;

			/**
			 * Text to display inside the tag.
			 */
			tagText?: string;

			/**
			 * If provided, the tag will be wrapped in a tooltip with this text.
			 *
			 * - Tooltip variant can be controlled with `tooltipVariant` prop.
			 * - Tooltip position can be controlled with `tooltipPosition` prop.
			 */
			tooltip?: React.ReactNode;

			/**
			 * The variant/color scheme of the tooltip.
			 */
			tooltipVariant?: TooltipVariant;
		}
	>;

type TagComponent = <C extends React.ElementType = "span">(
	props: TagProps<C>,
) => React.ReactNode;

export const Tag: TagComponent = forwardRef(
	<C extends React.ElementType = "span">(
		{
			as,
			variant = TagVariant.Primary,
			size = TagSize.Default,
			iconLeft,
			iconRight,
			className,
			tagText,
			children,
			tooltip,
			tooltipVariant,
			...props
		}: TagProps<C>,
		ref?: PolymorphicRef<C>,
	) => {
		const Component = as ?? "span";

		const tooltipSize = {
			[TagSize.Small]: QuestionMarkReferenceSize.ExtraSmall,
			[TagSize.Default]: QuestionMarkReferenceSize.Small,
		}[size];

		const tag = (
			<Component
				ref={ref}
				className={clsx(
					"size-sm:py-1 size-sm:h-[18px] md:size-sm:h-6 group inline-flex h-6 items-center justify-center rounded-[4px] px-2 md:h-7",
					size === TagSize.Small && "size-sm",
					// Full color variants
					variant === TagVariant.Primary && ["bg-primary text-white"],
					variant === TagVariant.Secondary && ["bg-secondary text-white"],
					variant === TagVariant.Success && ["bg-success text-white"],
					variant === TagVariant.Neutral && ["bg-neutral-400 text-white"],
					variant === TagVariant.Warning && ["bg-warning text-white"],
					variant === TagVariant.Danger && ["bg-danger text-white"],
					variant === TagVariant.Blue && ["bg-blue text-white"],
					variant === TagVariant.Violet && ["bg-violet text-white"],
					variant === TagVariant.Yellow && ["bg-yellow text-white"],
					// Muted color variants
					variant === TagVariant.MutedPrimary && [
						"bg-primary-100 text-primary-600",
					],
					variant === TagVariant.MutedSecondary && [
						"bg-secondary-100 text-secondary-600",
					],
					variant === TagVariant.MutedSuccess && [
						"bg-success-100 text-success-600",
					],
					variant === TagVariant.MutedWarning && [
						"bg-warning-100 text-warning-600",
					],
					variant === TagVariant.MutedDanger && [
						"bg-danger-100 text-danger-600",
					],
					variant === TagVariant.MutedNeutral && [
						"bg-neutral-100 text-neutral-600",
					],
					variant === TagVariant.MutedBlue && ["bg-blue-100 text-blue-600"],
					variant === TagVariant.MutedViolet && [
						"bg-violet-100 text-violet-600",
					],
					variant === TagVariant.MutedYellow && [
						"bg-yellow-100 text-yellow-600",
					],
					className,
				)}
				{...props}
			>
				<React.Fragment>
					{iconLeft && (
						<span className="group-size-sm:text-lg pr-1 text-xl text-inherit">
							{iconLeft}
						</span>
					)}
					<div className="text-overline-sm group-size-sm:text-[10px] md:group-size-sm:text-xs group-size-sm:leading-none text-xs leading-none tracking-normal">
						{tagText || children}
					</div>
					{iconRight && (
						<span className="group-size-sm:text-lg pl-1 text-xl text-inherit">
							{iconRight}
						</span>
					)}
					{tooltip && (
						<span className="pl-1">
							<Tooltip.QuestionMarkReference
								size={tooltipSize}
								variant={QuestionMarkReferenceVariant.Transparent}
							/>
						</span>
					)}
				</React.Fragment>
			</Component>
		);

		if (tooltip) {
			return (
				<Tooltip content={tooltip} variant={tooltipVariant}>
					{tag}
				</Tooltip>
			);
		}

		return tag;
	},
);
