import React from "react";
import clsx from "clsx";
import Image from "next/image";
import { Text, TextVariants } from "@jasperlabs/jux-next";

import RenderIfCondition from "./RenderIfCondition";

export function getInitials(name: string, fallback = "?") {
	if (!name || typeof name !== "string") {
		return fallback;
	}
	return name
		.replace(/\s+/, " ")
		.split(" ") // Repeated spaces results in empty strings
		.slice(0, 2)
		.map((v) => v && v[0].toUpperCase()) // Watch out for empty strings
		.join("");
}

type Props = {
	name: string;
	thumbnailImageUrl?: string;
	className?: string;
	isGrayscale?: boolean;
	size?: "default" | "large";
};

const textVariantForSize: Record<
	NonNullable<Props["size"]>,
	TextVariants["variant"]
> = {
	large: "subheading-lg",
	default: "label-sm",
};

const Avatar = ({
	name,
	className,
	size = "default",
	isGrayscale,
	thumbnailImageUrl,
}: Props) => {
	const abbreviation = getInitials(name);

	return (
		<div
			className={clsx(
				"rounded-full bg-blue-100 flex text-center items-center justify-center lg:h-12 lg:w-12 flex-shrink-0 relative overflow-hidden",
				size === "large" && "w-12 h-12 lg:h-16 lg:w-16",
				size === "default" && "w-10 h-10 lg:h-12 lg:w-12",
				className,
			)}
		>
			<RenderIfCondition
				condition={!!thumbnailImageUrl}
				fallback={
					<Text
						variant={textVariantForSize[size]}
						className="text-neutral-600 "
					>
						{abbreviation}
					</Text>
				}
			>
				<Image
					src={thumbnailImageUrl!}
					alt={name}
					width={250}
					height={250}
					className={clsx(
						"rounded-full absolute object-cover w-full h-full",
						isGrayscale && "filter grayscale",
					)}
				/>
			</RenderIfCondition>
		</div>
	);
};

export default Avatar;
