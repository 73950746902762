import React from "react";
import clsx from "clsx";

type EmptyStateProps = {
	emptyTitle: string;
	emptyMessage?: string;
};

const EmptyState = ({
	emptyTitle,
	emptyMessage,
	...rest
}: React.ComponentPropsWithoutRef<"div"> & EmptyStateProps) => (
	<div
		{...rest}
		className="flex flex-col items-center justify-center text-center"
	>
		<h4 className="text-subheading-default text-neutral-500 mb-3">
			{emptyTitle}
		</h4>
		{emptyMessage && (
			<p className="text-body-default text-color-body-text">{emptyMessage}</p>
		)}
	</div>
);

const CenteredLayout = ({
	className,
	children,
}: {
	className?: string;
	children: React.ReactNode;
}) => (
	<div
		className={clsx(
			"flex h-full w-full flex-col items-center justify-center",
			className,
		)}
	>
		{children}
	</div>
);

EmptyState.CenteredLayout = CenteredLayout;

export default EmptyState;
