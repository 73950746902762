"use client";

import { useSearchParams, useRouter } from "next/navigation";

type Props = {
	url: string;
};

const RedirectWithParams = ({ url }: Props) => {
	const router = useRouter();
	const searchParams = useSearchParams();
	const queryString = searchParams.toString();

	const path = `${url}${queryString ? `?${queryString}` : ""}`;

	router.push(path);

	return null;
};

export default RedirectWithParams;
