import React from "react";
import clsx from "clsx";
import { tv, type VariantProps } from "tailwind-variants";
import { PolymorphicComponentProps } from "../../types/polymorphic-component";

export const text = tv(
	{
		variants: {
			variant: {
				"heading-lg":
					"no-shrink:text-5xl font-display text-3xl font-medium leading-tight antialiased sm:text-4xl md:text-5xl text-heading",
				"heading-default":
					"no-shrink:text-4xl font-display text-2xl font-medium leading-tight antialiased sm:text-3xl md:text-4xl text-heading",
				"heading-sm":
					"no-shrink:text-3xl font-display text-2xl font-medium leading-tight antialiased md:text-3xl text-heading",
				"heading-xs":
					"no-shrink:text-2xl font-display text-xl font-medium leading-tight antialiased md:text-2xl text-heading",
				"subheading-lg":
					"no-shrink:text-2xl text-xl font-bold leading-normal antialiased md:text-2xl text-heading",
				"subheading-default":
					"text-md no-shrink:text-xl font-bold leading-normal antialiased md:text-xl text-heading",
				"subheading-sm":
					"text-md font-bold leading-normal antialiased text-heading",
				"subheading-xs":
					"text-sm font-bold leading-normal antialiased text-heading",
				"label-default": "text-base font-bold antialiased",
				"label-sm": "text-xs font-bold antialiased",
				"body-xl":
					"text-md leading-expanded font-normal antialiased md:text-xl text-color-body",
				"body-lg":
					"text-md no-shrink:text-lg leading-expanded font-normal antialiased md:text-lg body-lg text-color-body",
				"body-default":
					"text-md leading-expanded font-normal antialiased text-color-body",
				"body-sm":
					"leading-expanded text-base font-normal antialiased text-color-body",
				"body-xs":
					" leading-expanded text-sm font-normal antialiased text-color-body",
				"overline-default":
					"md:text-md no-shrink:text-md leading-expanded text-sm font-bold uppercase tracking-widest antialiased",
				"overline-sm":
					"no-shrink:text-md leading-expanded text-sm font-bold uppercase tracking-widest antialiased",
				"caption-default":
					"no-shrink:text-base text-xs leading-normal antialiased md:text-base",
				"caption-sm": "text-xs leading-normal antialiased",
			},
			color: {
				primary: "!text-primary",
				secondary: "!text-secondary",
				heading: "!text-heading",
				black: "!text-black",
				white: "!text-white",
				body: "!text-color-body",
				muted: "!text-color-body-muted",
				inherit: "!text-inherit",
				test: "!text-success",
			},
		},
		defaultVariants: {
			variant: "body-default",
		},
	},
	{ responsiveVariants: true },
);

export type TextVariants = VariantProps<typeof text>;
export type TextProps<C extends React.ElementType> = PolymorphicComponentProps<
	C,
	{
		children: React.ReactNode;
		className?: string;
		variant?: TextVariants["variant"];
		color?: TextVariants["color"];
	}
>;

export const Text = <C extends React.ElementType = "h2">({
	as,
	children,
	className,
	...props
}: TextProps<C>) => {
	const Component = as ?? "h2";

	return children ? (
		<Component className={clsx(text(props), className)}>{children}</Component>
	) : null;
};
